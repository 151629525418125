<template>
    <div>
        <table class="py-5">
            <tr>
                <th>{{$t('book.bookingData.name')}}<v-divider class="my-2"></v-divider></th>
                <th>{{$t('book.bookingData.birth')}}<v-divider class="my-2"></v-divider></th>
                <th>{{$t('book.bookingData.passport')}}<v-divider class="my-2"></v-divider></th>
                <th>{{$t('book.bookingData.expiry')}}<v-divider class="my-2"></v-divider></th>
                <th>{{$t('book.bookingData.issue')}}<v-divider class="my-2"></v-divider></th>
            </tr>
            <tr v-for="(item, i) in items" :key="i">
                <td>{{item.passengerFirstName}} {{item.passengerLastName}}<v-divider class="mt-2"></v-divider></td>
                <td>{{formatDate(item.date_of_birth.replace(/\//g, '-'), 'birth')}}<v-divider class="mt-2"></v-divider></td>
                <td>{{item.passport_number}}<v-divider class="mt-2"></v-divider></td>
                <td>{{formatDate(item.passport_expire_date)}}<v-divider class="mt-2"></v-divider></td>
                <td>{{item.passport_issue_country}}<v-divider class="mt-2"></v-divider></td>
                <v-divider></v-divider>
            </tr>
        </table>
    </div>
</template>

<script>
export default {
  props: ['items'],
  data () {
    return {
    }
  },
  methods: {
    formatDate (date, type) {
      if (!date) return null
      const [year, month, day] = date.split('-')
      const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
      const newDate = `${day} ${months[month - 1]} ${year}`
      if (type === 'birth') return newDate
      else return newDate
    }
  }
}
</script>

<style scoped>
    table {
        border: 1.5px solid #d7d7d7;
        text-align: center;
        width: 100%;
    }
</style>
